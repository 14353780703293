var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticStyle:{"position":"relative"},attrs:{"no-close-on-backdrop":"","size":"lg","id":"mergeModal","body-class":"p-4","title":_vm.$t('general.mergeProgress'),"static":""},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.isLoading,"variant":"success"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")]),_c('b-button',{staticClass:"mr-2 float-right",on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1)]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"color":"#f1b44c","width":70,"height":70,"loader":"dots","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("general.name")))]),_c('b-form-input',{class:{
          'is-invalid':
            _vm.mergedProgressFormSubmit && _vm.$v.mergedProgressForm.name.$error,
        },attrs:{"placeholder":_vm.$t('general.enterName')},model:{value:(_vm.mergedProgressForm.name),callback:function ($$v) {_vm.$set(_vm.mergedProgressForm, "name", $$v)},expression:"mergedProgressForm.name"}}),(_vm.mergedProgressFormSubmit && _vm.$v.mergedProgressForm.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mergedProgressForm.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t("general.requiredValue")))]):_vm._e()]):_vm._e()],1),_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("general.selectProgressToMerge")))]),_c('multiselect',{class:{
          'is-invalid':
            _vm.mergedProgressFormSubmit &&
            _vm.$v.mergedProgressForm.mergedProgress.$error,
        },attrs:{"placeholder":_vm.$t('general.selectProgress'),"label":"name","track-by":"id","options":_vm.progress,"multiple":true},on:{"select":_vm.selectProgressToMerge,"remove":_vm.removeProgressToMerge},model:{value:(_vm.mergedProgressForm.mergedProgress),callback:function ($$v) {_vm.$set(_vm.mergedProgressForm, "mergedProgress", $$v)},expression:"mergedProgressForm.mergedProgress"}}),(
          _vm.mergedProgressFormSubmit &&
          _vm.$v.mergedProgressForm.mergedProgress.$error
        )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mergedProgressForm.mergedProgress.required)?_c('span',[_vm._v(_vm._s(_vm.$t("general.requiredValue")))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('PrioritizationCalculationList',{attrs:{"isMerged":true},on:{"added":_vm.newPriorityCalculation,"select":_vm.selectPrioritizationCalculation},model:{value:(_vm.$v.mergedProgressForm.priorityCalculations),callback:function ($$v) {_vm.$set(_vm.$v.mergedProgressForm, "priorityCalculations", $$v)},expression:"$v.mergedProgressForm.priorityCalculations"}})],1)])],1),_c('hr'),(_vm.selectedPrioritizationCalculation)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('PrioritizationCalculationConfig',{model:{value:(_vm.selectedPrioritizationCalculation),callback:function ($$v) {_vm.selectedPrioritizationCalculation=$$v},expression:"selectedPrioritizationCalculation"}})],1)]):_vm._e(),_vm._l((_vm.mergedProgressForm.priorityCalculations),function(pc,index){return _c('div',{key:index},[(_vm.seeCriticalRatioConfig && pc.calculationType == 'CriticalRatio')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("general.CriticalRatio")))]),_c('b-tabs',_vm._l((pc
              .criticalRatioCalculationConfig
              .criticalRatioCalculationConfigsPerProgress),function(criticalRatioConfigForProgress,index){return _c('b-tab',{key:index,attrs:{"title":criticalRatioConfigForProgress.progress.name}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-3"},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("general.attribute")))]),_c('small',{staticClass:"mb-1",staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.$t("general.chooseProgressAttributeTxt")))]),_c('multiselect',{staticStyle:{"max-width":"220px"},attrs:{"placeholder":_vm.$t('general.chooseAttribute'),"options":_vm.attributes,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}},{key:"option",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}}],null,true),model:{value:(
                      criticalRatioConfigForProgress
                        .criticalRatioCalculationConfig.attribute
                    ),callback:function ($$v) {_vm.$set(criticalRatioConfigForProgress
                        .criticalRatioCalculationConfig, "attribute", $$v)},expression:"\n                      criticalRatioConfigForProgress\n                        .criticalRatioCalculationConfig.attribute\n                    "}})],1),_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("general.nva")))]),_c('small',{staticClass:"mb-1",staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.$t("general.nvaDescription")))]),_c('b-form-input',{staticStyle:{"max-width":"90px","height":"38px"},attrs:{"type":"number","step":"0.01","placeholder":"0"},nativeOn:{"blur":function($event){return _vm.handleNvaBlur.apply(null, arguments)}},model:{value:(
                      criticalRatioConfigForProgress
                        .criticalRatioCalculationConfig.nva
                    ),callback:function ($$v) {_vm.$set(criticalRatioConfigForProgress
                        .criticalRatioCalculationConfig, "nva", $$v)},expression:"\n                      criticalRatioConfigForProgress\n                        .criticalRatioCalculationConfig.nva\n                    "}})],1),_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("general.criticalRatioFormula")))]),_c('small',{staticClass:"mb-1",staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.$t("general.chooseDateTypeForCriticalRatioFormulaTxt")))]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('multiselect',{staticStyle:{"max-width":"170px"},attrs:{"placeholder":_vm.$t('general.selectCalculationTypes'),"options":_vm.dateTypes,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}},{key:"option",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.$t("general." + props.option)))])]}}],null,true),model:{value:(
                        criticalRatioConfigForProgress
                          .criticalRatioCalculationConfig.dateType
                      ),callback:function ($$v) {_vm.$set(criticalRatioConfigForProgress
                          .criticalRatioCalculationConfig, "dateType", $$v)},expression:"\n                        criticalRatioConfigForProgress\n                          .criticalRatioCalculationConfig.dateType\n                      "}}),_c('div',{staticClass:"ml-2"},[_vm._v(" - "+_vm._s(_vm.$t("general.now"))+" / "+_vm._s(_vm.$t("general.residualChargeAtProgress"))+" ")])],1)])],1)])])}),1)],1)]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }