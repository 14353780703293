<script>
import appConfig from "@/app.config";
import MaestroMenuLayout from "../components/maestro-menu-layout";
import PageHeader from "@/components/page-header";
import MergedProgressPrioritizationConfigModal from "./components/merged-progress-prioritization-config-modal";
import CalendarApiService from "@/services/calendarService";
import DeleteModal from "@/components/delete-modal";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "critical ratio",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    MaestroMenuLayout,
    PageHeader,
    MergedProgressPrioritizationConfigModal,
    DeleteModal,
  },
  data() {
    return {
      title: "critical ratio",
      opacity: 0,
      isLoading: false,
      mergedProgress: [],
    };
  },
  created() {
    this.isLoading = true;
    CalendarApiService.getMergedProgressSchedulingConfigList().then(
      (response) => {
        this.mergedProgress = response.data.data;
        this.isLoading = false;
      }
    );
  },
  mounted() {},
  computed: {},
  methods: {
    toggleSchedulingMenu() {
      this.$root.$emit("toggle-scheduling-menu");
    },
    openMergeProgressModal() {
      this.$bus.$emit("open-create-merged-progress-scheduling-config-modal");
    },
    openRemoveMergedProgressSchedulingConfigModal(mp) {
      this.$refs.deleteMergedProgressPrioritizationConfigModal
        .show(mp, mp.name)
        .then(
          (mergedConfig) => {
            CalendarApiService.deleteMergedProgressSchedulingConfig(
              mergedConfig.id
            ).then(() => {
              var index = this.mergedProgress
                .map((smp) => {
                  return smp.id;
                })
                .indexOf(mergedConfig.id);
              this.mergedProgress.splice(index, 1);
            });
          },
          () => {}
        );
    },
    savedMerge() {
      //reload merged progress
      this.isLoading = true;
      CalendarApiService.getMergedProgressSchedulingConfigList().then(
        (response) => {
          this.isLoading = false;
          this.mergedProgress = response.data.data;
        }
      );
    },
    editMergedProgressConfig(item) {
      this.$bus.$emit(
        "open-edit-merged-progress-scheduling-config-modal",
        item.id
      );
    },
  },
};
</script>

<template>
  <Layout :contentPadding="false">
    <MaestroMenuLayout>
      <div style="padding: 24px">
        <div style="display: flex">
          <i
            @click="toggleSchedulingMenu()"
            style="cursor: pointer; font-size: 1.5em; margin-right: 8px"
            class="fa fa-fw fa-bars"
          ></i>
          <PageHeader :title="$t('general.mergedProgress')" />
        </div>

        <div class="card">
          <div class="card-body">
            <div zclass="row">
              <div czlass="col-12">
                <div style="display: flex" class="mb-3">
                  <p class="card-title-desc" style="margin-bottom: 0">
                    {{ $t("general.mergedProgressPriorityCalculation") }}
                  </p>
                  <b-button
                    style="margin-left: auto"
                    @click="openMergeProgressModal"
                    variant="primary"
                  >
                    <i class="mdi mdi-merge"></i>
                    {{ $t("general.merge") }}
                  </b-button>
                </div>

                <div class="table-responsive" style="position: relative">
                  <loading
                    :active.sync="isLoading"
                    :can-cancel="false"
                    color="#f1b44c"
                    :width="70"
                    :height="70"
                    :opacity="opacity"
                    loader="dots"
                    :is-full-page="false"
                  >
                  </loading>
                  <table class="table table-hover table-centered">
                    <thead class="thead-light">
                      <tr>
                        <th>{{ $t("general.name") }}</th>
                        <th
                          style="width: 100px; text-align: center; width: 200px"
                        >
                          {{ $t("general.action") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(mp, index) in mergedProgress" :key="index">
                        <td>{{ mp.name }}</td>
                        <td style="text-align: center">
                          <b-dropdown
                            dropleft
                            size="lg"
                            class="card-drop"
                            variant="link"
                            toggle-class="text-decoration-none p-0"
                            no-caret
                          >
                            <template #button-content>
                              <i class="fas fa-ellipsis-h" />
                            </template>

                            <b-dropdown-item
                              @click="editMergedProgressConfig(mp)"
                            >
                              <i
                                class="fas fa-pencil-alt text-success mr-1"
                              ></i>
                              {{ $t("general.edit") }}
                            </b-dropdown-item>

                            <b-dropdown-item
                              @click="
                                openRemoveMergedProgressSchedulingConfigModal(
                                  mp
                                )
                              "
                            >
                              <i class="fas fa-trash-alt text-danger mr-1"></i>
                              {{ $t("general.delete") }}
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MergedProgressPrioritizationConfigModal @saved-merge="savedMerge" />
      <DeleteModal
        ref="deleteMergedProgressPrioritizationConfigModal"
        :title="$t('general.deleteMergedProgressSchedulingConfigTitle')"
        :message="$t('general.deleteMergedProgressSchedulingConfigContent')"
      />
    </MaestroMenuLayout>
  </Layout>
</template>
