<script>
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import CalendarApiService from "@/services/calendarService";
import ProgressService from "@/services/progress-service";
import PrioritizationCalculationList from "../../components/prioritization-calculation-list";
import PrioritizationCalculationConfig from "../../components/prioritization-calculation-config";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "maestro",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  props: {},
  components: {
    PrioritizationCalculationList,
    PrioritizationCalculationConfig,
  },
  data() {
    return {
      show: false,
      state: null,
      isLoading: false,
      mergedProgressFormSubmit: false,
      progress: [],
      mergedProgressForm: {
        name: null,
        mergedProgress: [],
        priorityCalculations: [],
      },
      selectedPrioritizationCalculation: null,
      criticalRatioFormulaForm: {
        nva: 0,
        dateType: "Delivery",
      },
      dateTypes: ["Delivery", "Installation", "Operation", "Production"],
      seeCriticalRatioConfig: false,
      seeProgressDependencyConfig: false,
    };
  },
  beforeCreate() {
    //fetch data to store
    this.$store.dispatch("calendar/fetchProgress");
  },
  created() {
    this.$bus.$on(
      "open-create-merged-progress-scheduling-config-modal",
      this.openCreateMergedProgressModal
    );
    this.$bus.$on(
      "open-edit-merged-progress-scheduling-config-modal",
      this.openEditMergedProgressModal
    );

    this.isLoading = true;
    CalendarApiService.getProgressSchedulingList().then((response) => {
      this.progress = response.data.data?.map((x) => x.progress) ?? [];
      this.progress.sort(ProgressService.getSortFct());
      this.isLoading = false;
    });
  },
  beforeDestroy() {
    this.$bus.$off(
      "open-create-merged-progress-scheduling-config-modal",
      this.openCreateMergedProgressModal
    );
    this.$bus.$off(
      "open-edit-merged-progress-scheduling-config-modal",
      this.openEditMergedProgressModal
    );
  },
  mounted() {},
  methods: {
    async save() {
      this.mergedProgressFormSubmit = true;
      this.$v.mergedProgressForm.$touch();

      var modelCopy = JSON.parse(JSON.stringify(this.mergedProgressForm));

      if (!this.$v.mergedProgressForm.$invalid) {
        this.isLoading = true;

        if (this.state == "create") {
          await CalendarApiService.createMergedProgressSchedulingConfig(
            modelCopy
          );
        } else if (this.state == "edit") {
          await CalendarApiService.updateMergedProgressSchedulingConfig(
            modelCopy
          );
        }

        this.isLoading = false;
        this.show = false;
        this.$emit("saved-merge");
        this.resetFields();
      }
    },
    close() {
      this.show = false;
      this.resetFields();
      this.$emit("close");
    },
    openCreateMergedProgressModal() {
      this.show = true;
      this.state = "create";
    },
    openEditMergedProgressModal(mergedProgressSchedulingConfigId) {
      this.show = true;
      this.state = "edit";

      //load config data
      CalendarApiService.getMergedProgressSchedulingConfig(
        mergedProgressSchedulingConfigId
      ).then((result) => {
        this.mergedProgressForm = result.data.data;

        //order calculations for draggable
        if (
          this.mergedProgressForm.priorityCalculations != null ||
          typeof this.mergedProgressForm.priorityCalculations != "undefined"
        ) {
          this.mergedProgressForm.priorityCalculations.sort((a, b) =>
            a.order > b.order ? 1 : b.order > a.order ? -1 : 0
          );
        }

        //order attributes dependencies
        if (
          this.mergedProgressForm.priorityCalculations != null ||
          typeof this.mergedProgressForm.priorityCalculations != "undefined"
        ) {
          let attributescalculationConfig =
            this.mergedProgressForm.priorityCalculations.filter((pc) => {
              return pc.calculationType == "Attributes";
            });
          if (attributescalculationConfig.length > 0) {
            attributescalculationConfig = attributescalculationConfig[0];
            if (
              Array.isArray(
                attributescalculationConfig.attributesCalculationConfig
                  ?.attributesDependencies
              )
            ) {
              attributescalculationConfig.attributesCalculationConfig?.attributesDependencies.sort(
                (a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
              );
            }
          }
        }
      });
    },
    resetFields() {
      this.mergedProgressFormSubmit = false;

      this.mergedProgressForm = {
        name: null,
        mergedProgress: [],
        priorityCalculations: [],
      };
    },
    getDefaultCriticalRatioModel() {
      let model = {
        attribute: "Products",
        nva: 0,
        dateType: "Delivery",
      };
      return model;
    },
    removeProgressToMerge(progress) {
      //retirer des config ratio critiques
      for (let pc of this.mergedProgressForm.priorityCalculations) {
        if (pc.calculationType == "CriticalRatio") {
          var index2 =
            pc.criticalRatioCalculationConfig.criticalRatioCalculationConfigsPerProgress
              .map((x) => {
                return x.progress.id;
              })
              .indexOf(progress.id);
          if (index2 != -1) {
            pc.criticalRatioCalculationConfig.criticalRatioCalculationConfigsPerProgress.splice(
              index2,
              1
            );
          }
        }
      }
    },
    selectProgressToMerge(progress) {
      for (let pc of this.mergedProgressForm.priorityCalculations) {
        if (pc.calculationType == "CriticalRatio") {
          let model = {
            progress: progress,
            criticalRatioCalculationConfig: this.getDefaultCriticalRatioModel(),
          };

          if (pc.criticalRatioCalculationConfig == null) {
            pc.criticalRatioCalculationConfig = {
              criticalRatioCalculationConfigsPerProgress: [],
            };
          }
          pc.criticalRatioCalculationConfig.criticalRatioCalculationConfigsPerProgress.push(
            model
          );
        }
      }
    },
    newPriorityCalculation(priorityCalculation) {
      let priorityCalculationModel = {
        calculationType: priorityCalculation.calculationType,
        criticalRatioCalculationConfig: null,
        attributesCalculationConfig: null,
        keywordsCalculationConfig: null,
      };
      if (priorityCalculation.calculationType == "CriticalRatio") {
        priorityCalculationModel.criticalRatioCalculationConfig = {
          criticalRatioCalculationConfigsPerProgress: [],
        };

        for (var p of this.mergedProgressForm.mergedProgress) {
          let model = {
            progress: p,
            criticalRatioCalculationConfig: this.getDefaultCriticalRatioModel(),
          };

          priorityCalculationModel.criticalRatioCalculationConfig.criticalRatioCalculationConfigsPerProgress.push(
            model
          );
        }
      } else if (priorityCalculation.calculationType == "Attributes") {
        priorityCalculationModel.attributesCalculationConfig = {
          attributesDependencies: [],
        };
      } else if (priorityCalculation.calculationType == "Keywords") {
        priorityCalculation.keywordsCalculationConfig = {
          keywordsDependencies: [],
        };
      }
    },
    selectPrioritizationCalculation(prioritizationCalculation) {
      this.selectedPrioritizationCalculation = prioritizationCalculation;
    },
    handleNvaBlur() {
      let criticalRatioCalculation =
        this.mergedProgressForm.priorityCalculations.filter((pc) => {
          return pc.calculationType == "CriticalRatio";
        });

      if (criticalRatioCalculation.length > 0) {
        criticalRatioCalculation = criticalRatioCalculation[0];
        if (
          typeof criticalRatioCalculation.criticalRatioCalculationConfig.nva ==
            "undefined" ||
          criticalRatioCalculation.criticalRatioCalculationConfig.nva == null ||
          criticalRatioCalculation.criticalRatioCalculationConfig.nva == ""
        ) {
          criticalRatioCalculation.criticalRatioCalculationConfig.nva = 0;
        }
      }
    },
  },
  computed: {},
  validations: {
    mergedProgressForm: {
      name: { required },
      mergedProgress: { required },
      priorityCalculations: {
        $each: {
          calculationType: { required },
          attributesCalculationConfig: {
            attributesDependencies: {
              $each: {
                attribute: { required },
                orderBy: { required },
              },
            },
          },
        },
      },
    },
    criticalRatioFormulaForm: {
      nva: { required },
      dateType: { required },
    },
  },
};
</script>

<template>
  <b-modal
    v-model="show"
    @close="close"
    no-close-on-backdrop
    size="lg"
    id="mergeModal"
    body-class="p-4"
    :title="$t('general.mergeProgress')"
    style="position: relative"
    static
  >
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    >
    </loading>
    <b-form @submit.prevent="">
      <b-form-group>
        <label>{{ $t("general.name") }}</label>
        <b-form-input
          :class="{
            'is-invalid':
              mergedProgressFormSubmit && $v.mergedProgressForm.name.$error,
          }"
          v-model="mergedProgressForm.name"
          :placeholder="$t('general.enterName')"
        ></b-form-input>
        <div
          v-if="mergedProgressFormSubmit && $v.mergedProgressForm.name.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.mergedProgressForm.name.required">{{
            $t("general.requiredValue")
          }}</span>
        </div>
      </b-form-group>

      <b-form-group>
        <label>{{ $t("general.selectProgressToMerge") }}</label>
        <multiselect
          :class="{
            'is-invalid':
              mergedProgressFormSubmit &&
              $v.mergedProgressForm.mergedProgress.$error,
          }"
          v-model="mergedProgressForm.mergedProgress"
          :placeholder="$t('general.selectProgress')"
          label="name"
          track-by="id"
          :options="progress"
          @select="selectProgressToMerge"
          @remove="removeProgressToMerge"
          :multiple="true"
        >
        </multiselect>
        <div
          v-if="
            mergedProgressFormSubmit &&
            $v.mergedProgressForm.mergedProgress.$error
          "
          class="invalid-feedback"
        >
          <span v-if="!$v.mergedProgressForm.mergedProgress.required">{{
            $t("general.requiredValue")
          }}</span>
        </div>
      </b-form-group>
      <div class="row">
        <div class="col-12">
          <PrioritizationCalculationList
            v-model="$v.mergedProgressForm.priorityCalculations"
            @added="newPriorityCalculation"
            @select="selectPrioritizationCalculation"
            :isMerged="true"
          />
        </div>
      </div>
    </b-form>

    <hr />

    <div class="row" v-if="selectedPrioritizationCalculation">
      <div class="col-12">
        <PrioritizationCalculationConfig
          v-model="selectedPrioritizationCalculation"
        />
      </div>
    </div>
    <div
      v-for="(pc, index) in mergedProgressForm.priorityCalculations"
      :key="index"
    >
      <!-- Critical ratio form -->
      <div
        class="row"
        v-if="seeCriticalRatioConfig && pc.calculationType == 'CriticalRatio'"
      >
        <div class="col-12">
          <h5 class="mb-3">{{ $t("general.CriticalRatio") }}</h5>
          <b-tabs>
            <b-tab
              v-for="(criticalRatioConfigForProgress, index) in pc
                .criticalRatioCalculationConfig
                .criticalRatioCalculationConfigsPerProgress"
              :key="index"
              :title="criticalRatioConfigForProgress.progress.name"
            >
              <div class="row">
                <div class="col-12 p-3">
                  <b-form-group>
                    <label>{{ $t("general.attribute") }}</label>
                    <small class="mb-1" style="display: block">{{
                      $t("general.chooseProgressAttributeTxt")
                    }}</small>
                    <multiselect
                      style="max-width: 220px"
                      :placeholder="$t('general.chooseAttribute')"
                      v-model="
                        criticalRatioConfigForProgress
                          .criticalRatioCalculationConfig.attribute
                      "
                      :options="attributes"
                      :allow-empty="false"
                      :show-labels="false"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span>{{ $t("general." + props.option) }}</span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span>{{ $t("general." + props.option) }}</span>
                      </template>
                    </multiselect>
                  </b-form-group>
                  <b-form-group>
                    <label>{{ $t("general.nva") }}</label>
                    <small class="mb-1" style="display: block">{{
                      $t("general.nvaDescription")
                    }}</small>
                    <b-form-input
                      @blur.native="handleNvaBlur"
                      type="number"
                      step="0.01"
                      v-model="
                        criticalRatioConfigForProgress
                          .criticalRatioCalculationConfig.nva
                      "
                      placeholder="0"
                      style="max-width: 90px; height: 38px"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label>{{ $t("general.criticalRatioFormula") }}</label>
                    <small class="mb-1" style="display: block">{{
                      $t("general.chooseDateTypeForCriticalRatioFormulaTxt")
                    }}</small>
                    <div style="display: flex; align-items: center">
                      <multiselect
                        style="max-width: 170px"
                        :placeholder="$t('general.selectCalculationTypes')"
                        v-model="
                          criticalRatioConfigForProgress
                            .criticalRatioCalculationConfig.dateType
                        "
                        :options="dateTypes"
                        :allow-empty="false"
                        :show-labels="false"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <span>{{ $t("general." + props.option) }}</span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <span>{{ $t("general." + props.option) }}</span>
                        </template>
                      </multiselect>
                      <div class="ml-2">
                        - {{ $t("general.now") }} /
                        {{ $t("general.residualChargeAtProgress") }}
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          :disabled="isLoading"
          @click="save"
          variant="success"
          class="float-right"
        >
          {{ $t("general.save") }}
        </b-button>
        <b-button class="mr-2 float-right" @click="close()">
          {{ $t("general.close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
